class LoadingPopup {

    constructor() {
        window.addEventListener("beforeunload", function (e) {
            const modal = $('#loadingPopup');
            modal.modal('hide');
        }, false);

        this.hasTrackingPopup = $('body').data('tracking-popup')
    }

    showModal() {
        if (this.hasTrackingPopup == 0) return window.location.href = `/${getLocale()}/payment`;
        if ($('.no-modal').length > 0) return window.location.href = `/${getLocale()}/payment`;
        let timeouts = []
        let timeBeforeRedirect = 8500;
        if($(".modal-loading-element-0").length > 0){
            timeBeforeRedirect = 5500;
        }
        timeouts.push(setTimeout(function () { window.location.href = `/${getLocale()}/payment`; }, timeBeforeRedirect));
        $(".automated-modal").on('hide.bs.modal', function () {
            for (var i = 0; i < timeouts.length; i++) {
                clearTimeout(timeouts[i]);
            }
        });
        function showText(target, message, index, interval) {
            if (message && message.length > 0 && index < message.length) {
                $(target).append(message[index++]);
                timeouts.push(setTimeout(function () { showText(target, message, index, interval); }, interval));
            }
        }

        let phoneData = $("#track-form").serializeArray();
        let phoneNumber = `+${phoneData.find(el => el.name === "dialCode").value} ${phoneData.find(el => el.name === "phoneNumber").value}`;
        phoneNumber = phoneNumber.length > 21 ? `${phoneNumber.slice(0, 18)}...` : phoneNumber;

        //function left alone because if it doesnt respond the modal does nothing
        let provider;
        let savedProvider = $(".automated-modal").data("provider");
        let savedPhone = $(".automated-modal").data("phone");
        if (savedProvider && savedPhone === phoneNumber) {
            provider = $(".automated-modal").data("provider");
        } else {
            $.get(`/api/phone-data`, $("#track-form").serialize(), function (data) {
                if (data.provider) {
                    provider = data.provider.length > 15 ? `${data.provider.slice(0, 12)}...` : data.provider;
                    $(".automated-modal").data("provider", provider)
                    $(".automated-modal").data("phone", phoneNumber)
                }
            })
        }

        $("#loadingPopup .modal-loading-element-1 .flag-container").empty()
        $(".selected-flag").first().clone().appendTo("#loadingPopup .modal-loading-element-1 .flag-container")


        $("#loadingPopup").find(".modal-loading-number").text(phoneNumber)
        $(".modal-loading-title").hide()
        $(".modal-loading-number").hide()
        $(".modal-loading-element-1").hide()
        $(".modal-loading-element-2").hide()
        $(".modal-loading-element-3").hide()
        $(".modal-loading-element-4").hide()
        $("#loadingPopup").modal("show");

        const titleToShow = $(".modal-loading-title").data("content");;
        $(".modal-loading-title").text("")
        $(".modal-loading-title").show()
        showText(".modal-loading-title", titleToShow, 0, 50)

        timeouts.push(setTimeout(() => {
            const phoneToShow = $(".modal-loading-number").text();
            $(".modal-loading-number").text("")
            $(".modal-loading-number").show()
            showText(".modal-loading-number", phoneToShow, 0, 50)
        }, 1200))

        let elementsToShow = [
            {
                target: ".modal-loading-element-1",
                waitingLeft: 2300,
                waitingRight: 2700,
            },
            {
                target: ".modal-loading-element-2",
                waitingLeft: 3200,
                waitingRight: 3700,
                waitingImg: 4100,
            },
            {
                target: ".modal-loading-element-3",
                waitingLeft: 4200,
                waitingRight: 4500,
                waitingImg: 5000,
            },
            {
                target: ".modal-loading-element-4",
                waitingLeft: 5200,
                waitingRight: 6000,
                waitingImg: 6500
            }
        ]

        if($(".modal-loading-element-0").length > 0){
            $(".modal-loading-number-container").text(phoneNumber)
            var country = $(".selected-flag").first().attr("title").split(":")[0]
            $(".country-container").html(country)
            $(".country-container").hide()
            elementsToShow =  [
                {
                    target: ".modal-loading-element-1",
                    waitingLeft: 600,
                    waitingRight: 1000
                },
                {},
                {
                    target: ".modal-loading-element-3",
                    waitingLeft: 1500,
                    waitingRight: 1800,
                    waitingImg: 2300
                },
                {
                    target: ".modal-loading-element-4",
                    waitingLeft: 2500,
                    waitingRight: 3300,
                    waitingImg: 3800
                }
            ]
        }

        for (let index = 0; index < elementsToShow.length; index++) {
            const element = elementsToShow[index];
            if(!element.target) continue;
            const leftTextToshow = $(element.target).find(".modal-loading-element-left span").data("content");
            let rightTextToshow = $(element.target).find(".modal-loading-element-right span").data("content");
            $(element.target).find(".modal-loading-element-left span").text("")
            $(element.target).find(".modal-loading-element-right span").text("")
            $(element.target).find(".modal-loading-element-right img").hide()
            $(element.target).find(".modal-loading-element-right .iti-flag").hide()
            if ($(element.target).find(".modal-loading-element-right .border-no-text").length > 0) {
                $(element.target).find(".modal-loading-element-right .spinner-border").hide()
            }
            timeouts.push(setTimeout(() => {
                $(element.target).show()
                showText(`${element.target} .modal-loading-element-left span`, leftTextToshow, 0, 50)
            }, element.waitingLeft))
            if (index > 0) {
                timeouts.push(setTimeout(() => {
                    if (index === 3) {
                        if ($(element.target).find(".modal-loading-element-right .border-no-text").length > 0) {
                            $(element.target).find(".modal-loading-element-right .spinner-border").show()
                        } else {
                            $(element.target).find(".modal-loading-element-right .spinner-border").hide()
                        }
                    } else if (index === 1) {
                        if (provider && provider.length > 0) rightTextToshow = provider;
                    }
                    showText(`${element.target} .modal-loading-element-right span`, rightTextToshow, 0, 50)
                }, element.waitingRight))
                timeouts.push(setTimeout(() => {
                    if (index === 3) {
                        $(element.target).find(".modal-loading-element-right .spinner-border").hide()
                        $(element.target).find(".modal-loading-element-right img").show()
                    } else if (index === 1) {
                        if (!provider || provider.length === 0) {
                            $(element.target).find(".modal-loading-element-right img").show()
                        }
                    } else {
                        $(element.target).find(".modal-loading-element-right img").show()
                    }
                }, element.waitingImg))
            } else {
                timeouts.push(setTimeout(() => {
                    $(element.target).find(".modal-loading-element-right .iti-flag").show()
                    if($(".modal-loading-element-0").length > 0){
                        $(".country-container").show()
                    }
                }, element.waitingRight))
            }
        }
    }

    sendRequest(customer, callback) {
        $.post(`/${getLocale()}/payment/info`, customer, callback)
    }
}
